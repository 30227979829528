
import {
    defineComponent,
} from "vue";

import {
    CloudUploadOutlined,
    BankOutlined,
    CreditCardOutlined
} from "@ant-design/icons-vue";
import url from "@/assets/error/error";
import editor from "./component/editor.vue";

export default defineComponent({
    name: "AccountWithdraw",
    components: {
        editor,
        CloudUploadOutlined,
        BankOutlined,
        CreditCardOutlined
    },
    data() {
        return {
            url: "/admin/withdraw/list",
            where: {},
            current: {},
            selection: [],
            visible: false,
            // 控制作废弹窗口,
            void_visible: false,
            // 表单内容 
            reject_form: {},
            // 附件loading
            loading: false,
            // 附件弹窗
            confirm_visible: false,
            // 附件列表
            fileList: [],
            // 图片弹窗
            preview_visible: false,
            // 资源路径
            previewImage: "",
        };
    },
    computed: {
        // 重构文件列表
        getFileList: {
            get() {
                return this.fileList;
            },
            set(val) {
                this.fileList = val.slice(val.length - 1);
            },
        },
        columns() {
            return [{
                    title: this.$t("客户代码"),
                    dataIndex: "user_account_code",
                    customRender: ({
                            record
                        }) =>
                        `${record.user_account_code}`,
                    width: 100,
                },
                {
                    title: this.$t("提现金额"),
                    dataIndex: "charge",
                    width: 120,
                    customRender: ({
                        record
                    }) => `${record.charge} ${record.currency}`
                },
                {
                    title: this.$t("收款账号"),
                    width: 280,
                    slots: {
                        customRender: "account"
                    }
                },
                {
                    key: "remark",
                    title: this.$t("备注"),
                    dataIndex: "remark",
                    slots: {
                        customRender: "remark"
                    }
                },
                {
                    title: this.$t("申请时间"),
                    key: "created",
                    align: "center",
                    dataIndex: "created",
                    width: 150
                },
                {
                    title: this.$t("确认时间"),
                    key: "audit_time",
                    align: "center",
                    dataIndex: "audit_time",
                    width: 150,
                    customRender: ({
                        text
                    }) => text || '-'
                },
                {
                    title: this.$t("附件"),
                    align: "center",
                    width: 100,
                    slots: {
                        customRender: "attachment",
                    }
                },
                {
                    title: this.$t("状态"),
                    key: "status",
                    dataIndex: "status",
                    width: 100,
                    slots: {
                        customRender: "status_active"
                    }
                },
                {
                    key: "action",
                    title: this.$t("操作"),
                    dataIndex: "action",
                    align: "center",
                    width: 120,
                    slots: {
                        customRender: "action",
                    }
                }
            ]
        }
    },
    methods: {
        // 处理错误图片
        handleError() {
            this.previewImage = url.base64URL;
        },
        // 展示附件
        showFile(row) {
            this.preview_visible = true;
            this.previewImage = row.attachment;
        },
        // 提交审核
        audit() {
            this.$refs.formFile.validate().then(() => {
                this.$http.post(`/admin/withdraw/audit/${this.current.id}`, this.reject_form).then((res) => {
                    if (res.data.code == 0) {
                        this.$message.success("share.success");
                        this.confirm_visible = false;
                        this.reload();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch((e) => {
                    this.$message.error(e.message);
                })
            });
        },
        // 处理预览
        handlePreview(file) {
            this.preview_visible = true;
            this.previewImage = file.thumbUrl;
        },
        // 上传附件
        handleUpload(imgFile) {
            this.loading = true;

            const data = new FormData();
            data.append("file", imgFile.file);

            this.imgFile = imgFile.file;
            this.$http.post("/admin/deposit/upload_attachment", data).then(res => {
                if (res.data.code == 0) {
                    this.reject_form.attachment = res.data.data;
                    this.$message.success(this.$t("操作成功"));
                    imgFile.onSuccess(res.data, imgFile.file);
                } else {
                    this.$message.error(res.data.msg);
                    this.fileList = [];
                }
            }).catch((e) => {
                this.fileList = [];
                imgFile.onError();
                this.$message.error(e.message);
            }).finally(() => {
                this.loading = false;
            });
        },
        reject() {
            this.$refs.formRef.validate().then(() => {
                this.$http.post(`/admin/withdraw/reject/${this.current.id}`, this.reject_form).then((res) => {
                    if (res.data.code === 0) {
                        this.void_visible = false;
                        this.$message.success(res.data.msg);
                        this.reload();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            })
        },
        // 审核
        confirm(row) {
            this.confirm_visible = true;
            this.current = row;
        },
        beginReject(row) {
            this.reject_form = {};
            this.current = row;
            this.void_visible = true;
        },
        // 提现申请
        withdrawCreate(row) {
            this.visible = true;
            this.current = row;
        },
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1,
            });
        },
        onClose() {
            this.visible = false;
        }
    }
});
